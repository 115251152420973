.clock-div{
    position: relative;
    display: flex;
    align-items: center;
    width: 100px;
    margin-left: 20px;
    cursor: pointer;
}

.clock-div img {
    height: 15px;
    margin-left: 5px;
}

.clock-div input {
    border: none;
    width: 40px;
}

.clock-div input:disabled{
    cursor: pointer;
}

.modal-main-div{
    position: absolute;
    display: flex;
    height: 200px;
    bottom: 25px;
    right: 5px;
    border: 1px solid #e5e7eb;
    z-index: 1000;
    padding: 5px;
    background-color: white;
    box-shadow: 2px 2px 2px #e5e7ebbd;
}

.modal-child-div{
    color: hsl(0, 0%, 20%);
    overflow: auto;
    border: none;
    padding: 5px;
}

.modal-child-div::-webkit-scrollbar {
    display: none;
  }

.each-el-clock{
    padding: 5px 10px;

}

.each-el-clock:hover{
    cursor: pointer;
    background-color: #006edc;
    color: white
}