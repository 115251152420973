.profile-page {
    position: absolute;
    width: calc(100vw - 368px);
    height: calc(100vh - 112px);
    left: 344px;
    top: 88px;
    background: #FFFFFF;
    border: 1px solid #DFE3E9;
    box-shadow: 0px 0px 8px rgba(159, 191, 255, 0.1);
    border-radius: 16px;
}

.form-div{
    width: 100%;
}

.title-div{
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 64px;
    border-bottom: 1px solid #DFE3E9;
}
.title-div h1 {
    margin-left: 16px;
    font-family: 'Montserrat arm';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 150%;
    color: #2C507B;
}
.title-div img{
    margin-right: 16px;
    cursor: pointer;
}

.info-page{
    display: flex;
    height: calc(100vh - 176px)
}

.choose-part{
    width: 28%;
    height: 100%;
    border-right: 1px solid #DFE3E9;
    padding-top: 16px;
}

.info-part{
    width: 72%;
    height: 100%;
}

/* chossepart */

.choose-section {
    display: flex;
    align-items: center;
    width: 180px;
    padding-left: 18px;
    height: 32px;
    cursor: pointer;
    border-radius: 4px;
    font-family: 'Montserrat arm';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 150%;
    color: #2C507B;
}

.active-section{
    background: #195FFC;
    color: #FFFFFF;
}

.section-icon{
    margin-right: 8px;
}

/* userform */

.flex-div{
    display: flex;
    width: 100%;
    justify-content: space-between;
    padding: 6px 16px 0px 16px ;
}

.user-page-input {
    box-sizing: border-box;
    align-items: center;
    padding: 8px 0px 8px 8px;
    width: 100%;
    height: 40px;
    background: #FFFFFF;
    border: 1px solid rgba(44, 80, 123, 0.5);
    border-radius: 4px;
    font-family: 'Montserrat arm';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 150%;
    color: #2C507B;
}

.user-page-p {
    margin-top: 10px;
    font-family: 'Montserrat arm';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 150%;
    color: #2C507B;
}

.upload-photo{    
    position: absolute;
}

.upload-back{
    position: relative;
    width: 178px;
    height: 178px;
    background: #CCDCFF;  

}

.user-img-div{
    position: relative;
    display: flex;
    justify-content: center;
}

.upload-btn {
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    bottom: 0px;
    width: 178px;
    height: 40px;
    background: #FFFFFF;
    border: 1px solid #2C507B;
    border-radius: 4px;
    
}

.insideload-btn {
    font-family: 'Montserrat arm';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 150%;
    color: #2C507B; 
    cursor: pointer;   
}

.delete-btn {
    font-family: 'Montserrat arm';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 150%;
    color: #F03426;
    width: 100%;
    cursor: pointer;
}

.delete-btn:disabled {
    cursor: default
}

.inputs-flex{
    display: flex;
    margin-left: 16px;
}

.submit-btn {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0px 8px;
    position: absolute;
    right: 16px;
    bottom: 16px;
    height: 38px;
    background: #195FFC;
    border-radius: 4px;
    font-family: 'Montserrat arm';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 150%;
    color: #FFFFFF;
}

.width50{
    width: 50%;
}

.width100{
    width: 100%;
}
.height100{
    height: 100%;
}

.online-border{
    border: 2px solid #195FFC;
    border-radius: 50%;
    filter: drop-shadow(0px 0px 4px #195FFC);
}

.mrg-div{
    margin: 0 16px 0 16px;
}

.pass-inputs-div{
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 8px 0px 8px 8px;
    gap: 8px;
    height: 40px;  
    background: #FFFFFF;
    border: 1px solid rgba(44, 80, 123, 0.5);
    border-radius: 4px;
}

.pass-inputs-div img{
    margin-right: 16px;
}

.pass-inputs {
    height: 21px;
    line-height: 150%;
    display: flex;
    align-items: center;
    color: #2C507B;
    width: 100%;
}

.audio-section{
    margin-top: 20px;
}

.video-div{
    display: flex;
    justify-content: center;
    
}

#video_test{
    width: 496px;
    height: 268px;
    border: 1px solid rgba(44, 80, 123, 0.5);
    border-radius: 4px;
}

.mrleft{
    margin-top: 30px;
    margin-left: 30%;
}

.checking-div{
    display: flex;
    justify-content: space-between;
    margin: 10px 16px 10px 16px
}

.checking-div h3 {
    text-align: center;
    font-family: 'Montserrat arm';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 150%;
    color: #2C507B;
}

.mon{
    display: none;
}

.check-btn {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0px 8px;
    background: #195FFC;
    border-radius: 4px;
    font-family: 'Montserrat arm';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 150%;
    display: flex;
    align-items: center;
    color: #FFFFFF;
    margin-right: 8px;
}

.cancel-btn {
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0px 8px;
    border: 1px solid #2C507B;
    border-radius: 4px;
    font-family: 'Montserrat arm';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 150%;
    display: flex;
    align-items: center;
    color: #2C507B;
}

.flex-divs{
    display: flex;
    justify-content: center;
}

.spaceing{
    display: flex;
    justify-content: space-between;
}

.pid {
    border-radius: 8px;
    width: 8%;
    height: 16px;
    margin-right: 2%;
}

.lang-div{
  margin-left: 16px;
}

.choosed-lang {
    margin-left: 20px;
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 2px 4px;
    gap: 5px;
    width: 50%;
    height: 40px;
    font-family: 'Montserrat arm';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 150%;
    display: flex;
    align-items: center;
    color: #FFFFFF;
    background: #195FFC;
    border-radius: 4px;
}

.mob{
    display: none;
}

.lang-item {
    margin-left: 20px;
    font-family: 'Montserrat arm';
    font-style: normal;
    font-weight: 400;
    height: 40px;
    font-size: 14px;
    line-height: 150%;
    display: flex;
    align-items: center;
    color: #2C507B;
}

.flexizeing{
    display: flex;
    align-items: center;
    cursor: pointer;
}

.lang-img{
    width: 16px;
    height: 16px;
}

.lang-img-mr{
    margin: 0 5px 0 5px
}

@media only screen and (max-width: 600px){
    .pc {
        display: none;
    }
    
    .mob {
        display: block
    }

    .profile-page {
        position: inherit;
        width: calc(100vw);
        height: calc(100vh - 60px);
        border-radius: 0;
    }

    .info-page{
        height: calc(100vh - 176px)
    }

    .choose-part{
        width: 50px;
        height: 100%;
        border-right: 1px solid #DFE3E9;
        padding-top: 16px;
    }

    .choose-section{
        width: 46px;
        padding-left: 15px;
        padding-right: 15px;
    }

    .section-icon{
        margin: 0
    }

    .upload-back{
        width: 110px;
        height: 110px;    
    }

    .upload-btn {
        width: 110px;
        height: 40px;        
    }

    .info-part{
        width: calc(100vw - 50px);
        height: 100%;
    }

    .user-img-div{
        margin-left: 16px;
    }
   
    .mob-patronymic{
        width: calc(200% + 16px);
    }

    .checking-div{
        display: block
    }

    .flex-divs{
        margin-bottom: 10px;
    }

    #video_test{
        width: 100%;
        height: auto;
    }

    .pc-head-sections{
        display: none;
    }

    .video-div{
        display: flex;
        justify-content: center;
    }
}