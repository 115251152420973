.room-name-time {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-left: 24px;
    font-family: 'Montserrat arm';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 150%;
    color: #FFFFFF;
}

.room-options-bar{
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: center;
}


.conf-footer{
    height: 64px;
    width: 100vw;
}

.conf-body{
    height: calc(100vh - 64px);
}

.conf-chat{
    height: calc(100vh - 128px);
    margin: 0 24px 0 0
}

.room-chat-main {
    position: absolute;
    right: 5px;
    width: 500px;
    height: calc(100vh - 128px);
    background: #FFFFFF;
    border: 1px solid #DFE3E9;
    box-shadow: 0px 0px 8px rgba(159, 191, 255, 0.1);
    border-radius: 16px;
}

.room-chat-body{
    max-height: calc(100vh - 208px);
    overflow-y: auto;
}


.bg-my-msg {
    padding: 8px;
    background-color: #195FFC;
    border-radius: 0px 8px 8px 8px;
    width: 318px;
    font-family: 'Montserrat arm';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: #FFFFFF;
    margin-bottom: 10px;
    word-break: break-word;
    margin-right: 16px;
}

.bg-others-msg {
    padding: 8px;
    background-color: #EAF0F5;
    border-radius: 0px 8px 8px 8px;
    width: 318px;
    font-family: 'Montserrat arm';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: #2C507B;
    margin-bottom: 10px;
    word-break: break-word;
}

.conf-message-div{
    display: flex;
    flex-direction: column;
    
}

.conf-chat-name {
    font-family: 'Montserrat arm';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    display: flex;
    align-items: center;
    color: #2C507B;
}

.my-msg-chat-div{
    margin-left: 136px
}

.chat-send-hole-div{
    position: absolute;
    width: 494px;
    border-bottom-left-radius: 16px;
    bottom: 0;
    right: 4px;
    height: 80px;
    background: #FFFFFF;
}

.chat-send-div {
    position: absolute;
    bottom: 0px;
    margin: 0px 16px 16px 16px;
    display: flex;
    justify-content: space-between;
    height: 52px;
    align-items: center;
    background: #F5F7FB;
    border: 1px solid #DFE3E9;
    border-radius: 4px;
}

.chat-upload-icon{
    width: 12px;
    height: 13px;
    margin: 0 8px 0 16px;
    cursor: pointer;
}

.conf-input {
    background: transparent;
    width: 400px;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 21px;
    color: #2C507B;
}

.conf-input-upload{
    display: flex;
    align-items: center;
}

.conf-chat-send-btn{
    cursor: pointer;
    margin-right: 5px;
}

.participants-div{
    background: #F5F7FB;
    border: 1px solid #DFE3E9;
    border-radius: 8px;
}

@media only screen and (max-width: 600px) {
    .room-chat-main {
        position: absolute;
        z-index: 1000;
        right: 0px;
        width: 100vw;
        height: calc(100vh - 128px);
        background: #FFFFFF;
        border: 1px solid #DFE3E9;
        box-shadow: 0px 0px 8px rgba(159, 191, 255, 0.1);
        border-radius: 0px;
    }

    .chat_message{
        width: calc(100% - 33px);
    }

    .chat-send-hole-div{
        width: 100vw;
        right: 0;
        
    }

    .mob-vid{
        font-weight: 600;
     }
     

    .conf-input{
        width: calc(100% - 43px);
    }
}