.main-div {
    position: relative;
    width: 100vw;
    height: 100vh;
    background: #F3F7FF;
}

.fontstyles {
    font-family: 'Montserrat arm';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: #2C507B;
}

.titeles {
    font-size: 16px;
}
/* navbar */
.user-navbar {
    display: flex;
    align-items: center;
    box-sizing: border-box;
    position: relative;
    width: 100vw;
    height: 64px;
    left: 0px;
    top: 0px;
    background: #FFFFFF;
}

.user-img-and-name{   
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}

.display-right{
    display: flex;
    position: absolute;
    right: 10px;
    justify-content: center;
    align-items: center;
}

.user-img-and-name img{
    border: 2px solid #195FFC;
    border-radius: 50%;
    margin-right : 10px;
    width: 40px;
    height: 40px;
}

.user-img-main{
    border-radius: 50%;
    margin-right : 10px;
    cursor: pointer
}
.button {
    display: flex;
    align-items: center;
    padding: 0px 8px;
    height: 35px;
    margin-right: 15px;  
    border: 1px solid #195FFC;
    border-radius: 4px;
    color: #195FFC;
}
/* invetions */

.inventions-my {
    position: absolute;
    width: calc((100vw - 392px)/2);
    height: calc(100vh - 112px);
    top: 88px;
    left: 344px;
    background: #FFFFFF;
    box-shadow: 0px 0px 8px rgba(159, 191, 255, 0.1);
    border-radius: 16px;
    overflow: auto;
}


.inventions-me {    
        position: absolute;
        width: calc((100vw - 392px)/2);
        height: calc(100vh - 112px);
        top: 88px;
        left: calc(368px + (100vw - 392px)/2);
        background: #FFFFFF;
        box-shadow: 0px 0px 8px rgba(159, 191, 255, 0.1);
        border-radius: 16px;
        overflow: auto;
}

.epmty-list{
    position: absolute;
    top: calc(50vh - 112px);
    left: calc((50vw - 392px)/2)
}


.describtions {
    position: relative;
    height: 48px;
    background: rgba(201, 229, 252, 0.4);
}

.dash {
    position: absolute;
    top: 12px;
    width: 100%;
    height: 24px;
    border-left: 1px solid #DFE3E9
}

.schedule {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    padding: 0px;
    position: absolute;
    width: 764px;
    height: 48px;
    left: 1132px;
    top: 160px;
}

.meeting-list-mob{
    display: none;
}

.listtitle-div{
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 50px;
}

/* list */
.list-tr{
    border-bottom: 2px solid #F3F7FF;
}

.list-td{
    height: 56px;
    text-align: center;
}

.status-td {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 25px;
    border-radius: 4px;
}

.settings-img{
    display: flex;
    align-items: center;
    justify-content: center;
}
/* colors */
.bggreen {
    background: #03C257
}

.bgyellow{
    background: #FEB62B
}

.bgred{
    background: #F03426
}

.bggrey{
    background: #EAF0F5
}

.bgblue{
    background: #195FFC
}
/* sidebar */
.user-sidebar {
    width: 320px;
    height: calc(100vh - 128px);
    background: #FFFFFF;
}

.users-list-scroll{
    width: 100%;
    height: calc(100% - 28px);
    overflow: auto;
}

.search-input-div {
    display: flex;
    width: 244px;
    height: 40px;
    background: #F5F7FB;
    border-radius: 8px;
    align-items: center;
    margin-left: 24px;
    margin-right: 6px;
    margin-bottom: 5px;
}

.search-logo-back {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 32px;
    height: 32px;
    background: #FFFFFF;
    box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
    margin: 0 5px
}
.search-logo {
    box-sizing: border-box;
    position: absolute;
    width: 24px;
    height: 24px;
}

.search-input {
    height: 24px;
    width: 100%;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 150%;
    background: #F5F7FB;
    color: #9FAEBF;
    width: 195px;
}

.eachuser{
    width: 320px;
    height: 64px;
}

.eachuser:hover{
    background: #F3F7FF
}

.each-user-div{
    position: relative;
    display: flex;
    align-items: center;
    width: 100%;
}

.each-img {
    border-radius: 50%;
    width: 32px;
    height: 32px;
    margin: 16px 10px 16px 24px
}
.each-chontrollers-div{
    display: flex;
    right: 0;
    justify-content: right;
    margin-right: 18px;
}
.each-div1  {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 32px;
    height: 32px;
    border-radius: 4px;
    cursor: pointer;
}
.each-div2{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 32px;
    height: 32px;
    border-radius: 4px;
    cursor: pointer;
}
.each-div1:hover{
    background: rgba(3, 194, 87, 0.2)
}
.each-div2:hover{
    background: rgba(25, 95, 252, 0.2)
}
.each-chontroller {
    width: 14px;
    height: 14px;  
}

.message-types {
    position: absolute;
    left: 112px;
    border-radius: 10px;
    margin-top: 94px;
    display: grid;
    align-items: center;
    width: 200px;
    z-index: 3;
    background: #FFFFFF;
    box-shadow: 1px 2px 10px rgba(0, 0, 0, 0.12);
    border-radius: 4px;
}

.message-icons{
    margin: 3px 5px 5px 15px; 
    width:15px; 
    height:15px
}
.msbtn{
    height: 40px;
    text-align: left;
    display:flex;
    align-items: center;
}
.msbtn:hover{
    background: #edf1fa
}
.admin-icon {
    width: 32px;
    height: 32px;
    
}

.admin-chat-div {
    position: fixed;
    bottom: 0;
    left: 0;
    background: #FFFFFF;
    width: 320px;
    height: 64px;
    display: flex;
    align-items: center;
    border-top: 1px solid #F5F7FB;
}

.admin-chat-div:hover{
    background: #F5F7FB;
    
}
.admin-div{
    display:flex;
    align-items: center;
    cursor: pointer;
    margin-left: 24px
}

.msg-circle{
    position: absolute;
    width: 12px;
    height: 12px;
    z-index: 1;
    left: 44px;
    bottom: 14px
}
.msg-circle-animated{
    z-index: 1;
    left: 44px;
    bottom: 14px
}

.user-modal {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 8px 0px;
    position: absolute;
    right: 16px;
    top: 45px;
    z-index: 1;
    width: 173px;
    height: 112px;
    background: #FFFFFF;
    box-shadow: 1px 2px 10px rgba(0, 0, 0, 0.12);
    border-radius: 4px;
    font-family: 'Montserrat arm';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 150%;
    color: #2C507B;
}

.user-modal-div {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 4px 16px;
    gap: 8px;
    height: 32px;
    cursor: pointer;
    width: 100%;
}

.user-modal-div:hover{
    background: #F3F7FF
}

.settings-icon{
    width: 12px;
    height: 12px;
}

.ans-waiting {
    width: 100%;
    align-items: center;
    padding: 0px 8px;
    background: #03C257;
    border-radius: 4px;
    font-family: 'Montserrat arm';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 150%;
    color: #FFFFFF;
    cursor: pointer;
}

.ans-waiting-div{
    width: 390px;
    height: 132px;
    background: #FFFFFF;
    border: 1px solid #DFE3E9;
    box-shadow: 0px 0px 8px rgba(159, 191, 255, 0.1);
    border-radius: 16px;
}

.ans-waiting-btns{
    display: flex;
    margin: 30px 16px;
}

.answer-waiting-reject{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 0px 8px;
    width: 100%;
    background: #F03426;
    border-radius: 4px;
    font-family: 'Montserrat arm';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 150%;
    color: #FFFFFF;
    height: 40px;
    margin-right: 16px;
}

.answer-waiting-reject img{
    margin-right: 5px;
}

.ans-waiting-status {
    margin-top: 16px;
    width: 100%;
    text-align: center;
    font-family: 'Montserrat arm';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 150%;
    color: #195FFC;
}

.font-status {
    font-family: 'Montserrat arm';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 150%;
    display: flex;
    align-items: center;
    color: #FFFFFF;
}

.font-declined-status {
    font-family: 'Montserrat arm';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 150%;
    display: flex;
    align-items: center;
    color: #3C4D5F;
}

.list-control-div {
    position: absolute;
    z-index: 4;
    right: 10px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 133px;
    background: #FFFFFF;
    box-shadow: 1px 2px 10px rgba(0, 0, 0, 0.12);
    border-radius: 4px;
}

.list-controller-btn {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 4px 16px;
    gap: 8px;
    width: 133px;
    height: 32px;
}

.list-controller-btn:hover{
    background: #F3F7FF;
}

.participate-controller {
    font-family: 'Montserrat arm';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 150%;
    display: flex;
    align-items: center;
    color: #03C257;
}

.cencel-controller {
    font-family: 'Montserrat arm';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 150%;
    display: flex;
    align-items: center;
    color: #F03426;
}

.change-controller {
    font-family: 'Montserrat arm';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 150%;
    display: flex;
    align-items: center;
    color: #2C507B;
}

.mob-main-div{
    display: none;
}

.mob-each-div{
    display: none;
}

@media only screen and (max-width: 600px){
    .main-div {
        display: none
    }

    .mob-main-div{
        display: block;
        background: #F3F7FF;
    }


.fontstyles {
    font-size: 14px;
}
.titeles {
    font-size: 14px;
    width: 70%;
}

.user-navbar {
    justify-content: space-between;
    width: 100vw;
    height: 60px;
    border-bottom: 2px solid #F3F7FF;
}

.user-img-and-name img{
    margin-right : 7px;
    width: 30px;
    height: 30px;
}

.button {
    padding: 0px 6px;
    width: 27px;
    height: 27px;
    margin-right: 7px;  
}

.user-img-and-name{   
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}

.display-right{
    margin-right: 5px;
    width: 50%;
    display: flex;
    justify-content: right;
    align-items: center;
}

.user-sidebar {
    width: 100%;
    height: calc(100vh - 128px);
    padding: 10px 14px 0 14px
}

.mob-sidenav{
    width: 100vw;
}

.epmty-list{
    position: unset;
}

.search-input-div {
    width: 100%;
    margin-left: 0;
    margin-right: 6px;
}

.search-input {
    height: 24px;
    width: calc(100% - 45px);
}

.eachuser{
    width: 100%;
    height: 64px;
}

.each-img {
    margin: 16px 10px 16px 4px
}

.each-chontrollers-div{
    margin-right: 0px;
}

.eachuser:hover{
    background: inherit
}
/* 
.each-div2{
    display: none;
} */

/* .mob-each-div{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 32px;
    height: 32px;
    border-radius: 4px;
    cursor: pointer;
} */

.admin-div{
    margin-left: 14px
}

.admin-chat-div {
    width: 100vw;
}

.inventions-mob{
    display: block;
}

.inventions-pc{
    display: none;
}

.meeting-list-mob{
    display: block;
    width: calc(100vw - 5px);
    /* height: calc(50vh - 60px); */
    overflow: hidden;
}

.meeting-list-pc{
    display: none;
}

.inventions-my {
    margin-top: 14px;
    position: inherit;
    width: 100vw;
    height: calc(50vh - 51px);
    overflow: auto;
    overflow-x: hidden;
    border-radius: 0
}

.new-conf-mob{
    width: 136px;
    font-size: 12px;
    height: 30px;
    margin-right: 9px;
}

.mob-conf-list-my{
    display: flex;
    margin-left: 14px;
    width: 100%;
    overflow: auto;
}

.align{
    align-items: center;
    justify-content: center;
}

.sizeing{
    width: fit-content;
}

.mob-conf-list-my::-webkit-scrollbar{
    display: none;
}
.my-mob-list{
    display: grid;
    background-color: #F3F7FF;
    width: 35vw;
    height: calc(50vh - 130px);
    margin-right: 10px;   
    box-shadow: 0px 0px 8px rgba(159, 191, 255, 0.1);
    border-radius: 16px; 
    padding: 14px;
    align-items: center;
}

.my-mob-list:hover{
    background-color: #9FAEBF;
}

.mob-each-conf{
   position: relative;

}

.listtitle-div{
    margin-bottom: 10px;
}

.headers{
    font-size: 13px;
    font-weight: 700;
}

.list-td {
    height: auto;
    font-style: italic;
    text-align: start;
}
.headers-div{
    color: rgba(201, 229, 252, 0.4);
}

.list-tr{
    display: grid;
    align-items: center;
    border-bottom: none;
}

.inventions-me {   
    margin-top: 14px;
    position: inherit;
    width: 100vw;
    height: calc(50vh - 51px);
    overflow: auto;
    border-radius: 0;
    margin-bottom: 14px
}

.list-control-div{
    top: 40%;
    left: 0px;
    right: 0
}

.msg-circle-animated{
    left: 25px;
}

.msg-circle{
    left: 25px
}

.ans-waiting-div{
    width: 100vw;
    height: 132px;
    background: #FFFFFF;
    border: 1px solid #DFE3E9;
    box-shadow: 0px 0px 8px rgba(159, 191, 255, 0.1);
    border-radius: 16px;
}

.answer-waiting-reject{
    margin-right: 0;
}
}