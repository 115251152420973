/* login */
.login-page{
    width: 100vw;
    height: 100vh;    
    background: #F3F7FF;
}

.background-div{
    width: 100vw;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    background: url(../../public/back.webp);
    background-position: center;
    background-size: cover;
}

.info-div{
    display: grid;
    align-items: center;
    box-sizing: border-box;
    width: 544px;
    height: 585px;
    text-align: center;
    background:  rgba(255, 255, 255, 0.1);
    border: 1px solid rgba(255, 255, 255, 0.2);
    backdrop-filter: blur(3px);
    border-radius: 32px;
    padding-top: 40px;
    /* transform: rotate(-90deg); */
}

.info-center{
    display: flex;
    justify-content: center;
    margin-bottom: 10px;
}

.info-input-div {
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 8px 0px 8px 8px;
    gap: 8px;
    width: 480px;
    height: 40px;
    left: 720px;
    top: 445px;
    background: #FFFFFF;
    border: 1px solid rgba(44, 80, 123, 0.5);
    border-radius: 4px;
}

.info-div p {
    font-size: 14px;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

 .info-input{
     width: 413px;
     height: 21px;
     line-height: 150%;
     display: flex;
     align-items: center;
     color: #2C507B;
 }

 .info-p{
    height: 21px;
    line-height: 150%;
    align-items: center;
    color: #2C507B;
 }

 .info-input ::placeholder {
    font-family: 'Montserrat arm';
     font-style: normal;
     font-weight: 300;
     font-size: 14px;
     opacity: 0.5;
 }

.eye {
    margin-right: 5px;
}

.head1{
    font-weight: 500;
    font-size: 24px;
    margin: 30px 0 30px 0
}

.head2{
    font-weight: 600;
    font-size: 32px;
    margin-bottom: 15px
}

.head3{
    font-weight: 600;
    font-size: 16px;
}

.fontstyle {
    font-family: 'Montserrat arm';
    font-style: normal;
    font-weight: 500;
    line-height: 100%;
    color: #FFFFFF;
}

.info-center button {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 0px 8px;
    width: 480px;
    height: 40px;
    background: #195FFC;
    border-radius: 4px;
    margin-top: 20px;
}

.label-p{
    text-align: left;
    margin-left:32px
}

.help-and{
    width:480px;
    display : flex;
    justify-content:space-between;
    margin-bottom: 30px;
}

.mergelyan-logo{   
    position: fixed;
    z-index : 1;
    left : 15px;
    top : 15px;
    height : 50px    
}

@media only screen and (max-width: 600px){
   
    .info-div{
        display: grid;
        align-items: center;
        box-sizing: border-box;
        width: 95%;
        height: 450px;
        text-align: center;
        background: rgba(255, 255, 255, 0.02);
        border: 1px solid rgba(255, 255, 255, 0.2);
        backdrop-filter: blur(3px);
        border-radius: 32px;
        /* transform: rotate(-90deg); */
    }
    
        
    .info-input-div {
        box-sizing: border-box;
        display: flex;
        flex-direction: row;
        align-items: center;
        padding: 8px 0px 8px 8px;
        gap: 8px;
        width: 90%;
        height: 30px;
        background: #FFFFFF;
        border: 1px solid rgba(44, 80, 123, 0.5);
        border-radius: 4px;
    }
    
    .info-div p {
        font-size: 12px;
    }
    
     .info-input{
         height: 21px;
         line-height: 150%;
         display: flex;
         align-items: center;
         color: #2C507B;
         width: 100%;
     }
    
     .info-input ::placeholder {
        font-family: 'Montserrat arm';
         font-style: normal;
         font-weight: 300;
         font-size: 10px;
         opacity: 0.5;
     } 

    .label-p{
        text-align: left;
        margin-left: 5%
    }

    .fontstyle {
        font-family: 'Montserrat arm';
        font-style: normal;
        font-weight: 500;
        line-height: 100%;
        font-size: 14px;
        color: #FFFFFF;
    }

    .head1{
        font-weight: 500;
        font-size: 18px;
        margin: 20px 0 15px 0
    }
    
    .head2{
        font-weight: 600;
        font-size: 26px;
        margin-bottom: 10px
    }
    
    .head3{
        font-weight: 600;
        font-size: 12px;
        margin-bottom: 20px
    }

    .info-center button {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        padding: 0px 8px;
        width: 90%;
        height: 40px;
        background: #195FFC;
        border-radius: 4px;
        margin-top: 15px;
    }
    
    .help-and{
        width:90%;
        display : flex;
        justify-content:space-between;
        margin-bottom: 0px;
    }
    .mergelyan-logo{   
        position: fixed;
        z-index : 1;
        left : 15px;
        top : 15px;
        height : 40px 
    }
}