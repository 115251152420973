.ms-div {
    position: absolute;
    width: 600px;
    height: 620px;
    z-index: 1;
    right: 5px;
    bottom: 5px;
    background: #FFFFFF;
    border: 1px solid #DFE3E9;
    box-shadow: 0px 0px 8px rgba(159, 191, 255, 0.1);
    border-radius: 16px;
}

.msg-window{
    height: 488px;
}

.msg-all{
    height: 540px;
}

#messages-small {
    overflow: auto;
}

.chat-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 64px;
    width: 601px;
    border: 1px solid #DFE3E9;
    border-top-left-radius: 16px;
    border-top-right-radius: 16px;
}

.chat-ser-img{
    width: 32px;
    height: 32px;
    margin: 0px 16px 0px 8px;    
}

.chat-icons{
    display: flex;
    margin-right: 6px;
}

.chat-icons img {
    width: 12px;
    height: 12px;
    margin-right: 10px;
    cursor: pointer;
}

.user-img-name-div {
    display: flex;
    align-items: center;
    font-family: 'Montserrat arm';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    display: flex;
    align-items: center;
    color: #2C507B;
}

.chat-nots {
    font-family: 'Montserrat arm';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 20px;
    color: #195FFC;
    width: 100%;
    text-align: center;
    margin: 3px 0 3px 0;
}


.my-msg {
    display: flex;
    padding: 8px;
    gap: 10px;
    width: 318px;
    height: auto;
    background: #195FFC;
    border-radius: 8px 0px 8px 8px;
    font-family: 'Montserrat arm';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: #FFFFFF;
    margin-top: 16px
}

.my-msg-div{
    display: flex;
    justify-content: right;
    margin-right: 16px;
}

.time-viewed {
    display: flex;
    justify-content: right;
    font-family: 'Montserrat arm';
    font-style: normal;
    font-weight: 400;
    font-size: 10px;
    line-height: 10px;
    display: flex;
    align-items: center;
    text-align: right;
    color: #AAAAAA;
    margin-top: 4px;
}

.time-viewed img{
    width: 14px;
    height: 8px;
}

.msg-delete-btn {
    width: 90px;
    display: flex;
    background: #FFFFFF;
    box-shadow: 1px 2px 10px rgba(0, 0, 0, 0.12);
    border-radius: 4px;
    align-items: center;
    cursor: pointer;
}

.msg-delete-btn img {
    width: 12px;
    height: 14px;
    margin-right: 5px;
    font-family: 'Montserrat arm';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 150%;
    display: flex;
    align-items: center;
    color: #2C507B;
}

.file-send-box{
    display: flex;
    padding: 4px 8px;
    background: #FFFFFF;
    border-radius: 0px 8px 8px 8px;
}

.file-send-font {
    font-family: 'Montserrat arm';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    display: flex;
    align-items: center;
    color: #2C507B;
}

.download-img{
    margin-left: 8px;
    width: 12px;
    height: 13px;
}

.download-box{
    display: flex;
    align-items: center;
}

.msg-me {
    position: relative;
    display: flex;
    align-items: flex-start;
    padding: 8px;
    gap: 10px;
    width: 318px;
    height: auto;
    background: #EAF0F5;
    border-radius: 0px 8px 8px 8px;
    font-family: 'Montserrat arm';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    display: flex;
    align-items: center;
    color: #2C507B;
    margin-top: 16px;
    margin-left: 16px;
}

.msg-size{
    text-align: left;
    word-break: break-word;
}

.me-time-viewd {
    width: 333px;
    justify-content: right;
    font-family: 'Montserrat arm';
    font-style: normal;
    font-weight: 400;
    font-size: 10px;
    line-height: 10px;
    display: flex;
    align-items: center;
    text-align: right;
    color: #AAAAAA;
    margin-top: 4px;
}

.send-div {
    position: absolute;
    bottom: 16px;
    width: 568px;
    height: 40px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 0px 4px;
    background: #F5F7FB;
    border: 1px solid #DFE3E9;
    border-radius: 4px;
    margin: 0 16px 0 16px
}

.text-send {
    width: 100%;
    height: 21px;
    margin-left: 18px;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 21px;
    color: #2C507B;
    background: #F5F7FB;
    width: 100%;
}

.send-msg{
    display: flex;
    align-items: center;
    margin-left: 14px;
    width: 90%;
}

.send-btn-privat{
    display: flex;
    height: 40px;
    align-items: center;
}

.fontstyle-ms {
    font-family: 'Montserrat arm';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    display: flex;
    align-items: center;
    color: #2C507B;
    cursor: pointer;
}

.m-l-17{
    margin-left: 17px;
}

@media only screen and (max-width:600px){
    .ms-div{
        position: absolute;
        width: 100vw;
        height: 100%;
        border-radius: 0;
        right: 0;
        bottom: 0px;
        overflow-x: hidden;
    }

    .msg-all{
        height: calc(100vh - 67px);
    }

    .chat-header{
        width: 100vw;
        border-top-left-radius: 0;
        border-top-right-radius: 0;
    }

    #messages-small{
        height: calc(100vh - 140px);
    }

    .send-div{
        width: 90vw;
    }

  
}