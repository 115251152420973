.group-chat-main {
    width: 600px;
    height: 433px;
    background: #FFFFFF;
    border: 1px solid #DFE3E9;
    box-shadow: 0px 0px 8px rgba(159, 191, 255, 0.1);
    border-radius: 16px;
}

.group-chat-header{
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 64px;
    padding: 0 16px;
    border-bottom: 1px solid #DFE3E9;   
}

.group-members-select {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 8px 12px;
    height: 40px;
    background: #FFFFFF;
    border-radius: 4px;
    margin-bottom: 16px;
}
    
.select-input{
    width: 100%;
    height: auto;
    outline: none;
    border: 0px solid #DFE3E9;
    font-family: 'Montserrat arm';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 150%;
    color: #2C507B;
}


.css-tj5bde-Svg{
    display: none
}

.text-input-section {
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    height: 200px;
    padding: 0px 4px;
    background: #F5F7FB;
    border: 1px solid #DFE3E9;
    border-radius: 4px;
    margin: 0 16px
}

.textarea-input{
    background: transparent;
    margin-top: 5px;
    margin-bottom: 16px;
    height: 180px;
    width: 508px;
    resize: none;
}

.upload-file-icon{
    margin: 10px 14px 16px 3px;
    width: 12px;
    height: 13px;
    cursor: pointer;
}

.send-btn {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 10px 16px;
    position: absolute;
    height: 40px;
    right: 36px;
    bottom: 36px;
    background: #195FFC;
    border-radius: 4px;
    cursor: pointer;
    font-family: 'Montserrat arm';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 150%;
    display: flex;
    align-items: center;
    color: #FFFFFF;
}

.fontstyle-group{
    font-family: 'Montserrat arm';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 150%;
    color: #2C507B;
}

.mr-16{
    margin: 16px 0 16px 16px
}

.select__control--is-focused{
    border: 1px solid #DFE3E9;
    height: auto;
}

@media only screen and (max-width:600px){
    .group-chat-main{
        width: 100vw;
    }
}