.dlt-main-div {
    width: 392px;
    height: 208px;
    background: #FFFFFF;
    border: 1px solid #DFE3E9;
    box-shadow: 0px 0px 8px rgba(159, 191, 255, 0.1);
    border-radius: 16px;
}

.dlt-header{
    display: flex;
    justify-content: center;
    align-items: center;
    height: 64px;
    border-bottom: 1px solid #DFE3E9;
    font-family: 'Montserrat arm';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 150%;
    color: #2C507B;
}

.dlt-question {
    padding: 30px 16px;
    margin-bottom: 30px;
    font-family: 'Montserrat arm';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 150%;
    color: #2C507B;
    border-bottom: 1px solid #DFE3E9;
}

.dlt-btns{
    display: flex;
    justify-content: right;
    margin: 16px 16px 0 16px;
}

.dlt-reject {
    width: 83px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding: 0px 8px;
    border: 1px solid #2C507B;
    border-radius: 4px;
    font-family: 'Montserrat arm';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 150%;
    color: #2C507B;
}

.dlt-accept{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 0px 8px;
    width: 105px;
    background: #F03426;
    border-radius: 4px;
    font-family: 'Montserrat arm';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 150%;
    color: #FFFFFF;
    height: 40px;
    margin-left: 16px;
}