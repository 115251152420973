.mk-main-div {
    position: absolute;
    width: 392px;
    max-height: 90vh;
    background: #FFFFFF;
    border: 1px solid #DFE3E9;
    box-shadow: 0px 0px 8px rgba(159, 191, 255, 0.1);
    border-radius: 16px;
    overflow: auto;
}

.mk-sizes{
    width: 392px;
    min-height: 650px;
    display: flex;
    align-items: center;
}

.mk-header{
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 64px;
    padding: 0 16px;
    border-bottom: 1px solid #DFE3E9;;
}

.mk-16{
    margin: 16px 16px 0px 16px
}

.mk-tb-16{
    margin: 16px 0;
}

.mk-input {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 8px 12px;
    height: 40px;
    background: #FFFFFF;
    border: 1px solid #EAF0F5;
    border-radius: 4px;
    width: 100%;
}

.mk-calendar {
    font-family: 'Montserrat arm';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 22px;
    text-align: center;
    color: #2C507B;
    width: 100%;
    box-shadow: inset 0px -1px 0px #F0F0F0;
    border-radius: 2px 0px 0px 0px;
}

.mk-time{
    display: flex;
    justify-content: space-between;
    margin-top: 10px;
}

.mk-btn {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 10px 8px;
    background: #195FFC;
    border-radius: 4px;
    font-family: 'Montserrat arm';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 150%;
    color: #FFFFFF;
}

.mk-btn-pos {
    display: flex;
    justify-content: right;
    margin: 16px;
}

.mk-font {
    font-family: 'Montserrat arm';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 150%;
    color: #2C507B;
}

.mk-font-17{
    font-size: 17px;
}

.mr-15{
    margin-top: 15px;
}

.mk-select-input {
    min-height: 40px;
}

@media only screen and (max-width: 600px){
    .mk-main-div {
        position: inherit;
        width: 100vw;
        max-height: 90vh;
        background: #FFFFFF;
        border: 1px solid #DFE3E9;
        box-shadow: 0px 0px 8px rgba(159, 191, 255, 0.1);
        border-radius: 0;
        overflow: auto;
    }

    .mk-sizes{
        display: flex;
        align-items: center;
        width: 100vw;
    }
}   
