.ans-main {
    width: 390px;
    height: 232px;
}

.ans-sizes{
    position: absolute;
    width: 390px;
    height: 232px;
    background: #FFFFFF;
    border: 1px solid #DFE3E9;
    box-shadow: 0px 0px 8px rgba(159, 191, 255, 0.1);
    border-radius: 16px;
}

.ans-caller {
    width: 100%;
    display: flex;
    align-items: center;
    margin-top: 40px;
    justify-content: center;
    font-family: 'Montserrat arm';
    font-style: normal;
    font-weight: 500;
    font-size: 24px;
    line-height: 20px;
    display: flex;
    align-items: center;
    color: #195FFC;
}

.ans-caller img {
    width: 48px;
    height: 48px;
    border: 2px solid #195FFC;
    border-radius: 50%;
    margin-right: 8px;   
}

.ans-calling{
    width: 100%;
    text-align: center;
    margin-top: 16px;
    margin-bottom: 23px;
    font-family: 'Montserrat arm';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 150%;
    color: #195FFC;
}

.ans-btns{
  display: flex;
  margin: 0 16px;
}

.ans-btns img{
    margin-right: 5px;
}

.ans-accept {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 100%;
    padding: 0px 8px;
    background: #03C257;
    border-radius: 4px;
    font-family: 'Montserrat arm';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 150%;
    color: #FFFFFF;
    height: 40px;
}

.ans-reject {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 0px 8px;
    width: 100%;
    background: #F03426;
    border-radius: 4px;
    font-family: 'Montserrat arm';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 150%;
    color: #FFFFFF;
    height: 40px;
    margin-right: 16px;
}

@media only screen and (max-width: 600px) {
    .ans-sizes{
        width: 100vw;
    }

    .ans-main {
        width: 100vw;
    }

   
}