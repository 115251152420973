.cnc-main {
    width: 392px;
    height: 266px;
    background: #FFFFFF;
    border: 1px solid #DFE3E9;
    box-shadow: 0px 0px 8px rgba(159, 191, 255, 0.1);
    border-radius: 16px;
}

.cnc-header {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 64px;
    text-align: center;
    border-bottom: 1px solid #DFE3E9;
    font-family: 'Montserrat arm';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 150%;
    color: #2C507B;
}
.cnc-input-div {
    border-bottom: 1px solid #DFE3E9;
    padding: 16px 16px 16px 16px;
    font-family: 'Montserrat arm';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 150%;
    color: #2C507B;
}

.cnc-input {
    padding: 0 8px;
    height: 40px;
    background: #FFFFFF;
    border: 1px solid #EAF0F5;
    border-radius: 4px;
    width: 360px;
    margin-top: 16px;
}

.cnc-btns{
    display: flex;
    justify-content: right;
    margin: 16px 16px 0 16px;
}

.cnc-reject{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 0px 8px;
    width: 105px;
    background: #F03426;
    border-radius: 4px;
    font-family: 'Montserrat arm';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 150%;
    color: #FFFFFF;
    height: 40px;
    margin-left: 16px;
    
}

.cnc-accept {
    width: 83px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding: 0px 8px;
    border: 1px solid #2C507B;
    border-radius: 4px;
    font-family: 'Montserrat arm';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 150%;
    color: #2C507B;
}