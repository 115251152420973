.main-newchat-div{
    width: 100vw;
    height: 100vh;
    background: #F3F7FF;
}

.new-page-chat-div{
    width: 50vw;
    height: 100vh;
    margin-left: 25vw;
    background: #FFFFFF;
}

.new-chat-header{
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 64px;
    border: 1px solid #DFE3E9;
}

.new-chat-user-img-name-div{
    display: flex;
    align-items: center;
    font-family: 'Montserrat arm';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    display: flex;
    align-items: center;
    color: #2C507B;
}

.new-chat-user-img{
    width: 32px;
    height: 32px;
    border-radius: 50%;
    margin: 0px 16px 0px 8px;  
}

.new-chat-icons{
    display: flex;
    margin-right: 6px;
}

.new-chat-icons img{
    width: 12px;
    height: 12px;
    margin-right: 10px;
    cursor: pointer;
}

.new-chat-nots{
    font-family: 'Montserrat arm';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 20px;
    color: #195FFC;
    width: 100%;
    text-align: center;
    margin: 3px 0 3px 0;
}

.new-msg-delete-btn{
    position: absolute;
    font-family: 'Montserrat arm';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 20px;
    color: #2C507B;
    height: 30px;
    padding: 0 8px;
    right: 16px;
    z-index: 2;
    display: flex;
    background: #FFFFFF;
    box-shadow: 1px 2px 10px rgba(0, 0, 0, 0.12);
    border-radius: 4px;
    align-items: center;
    cursor: pointer;
}

.new-msg-delete-btn img {
    margin-right: 5px;
}

.right-move{
    position: absolute;
    font-family: 'Montserrat arm';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 20px;
    color: #2C507B;
    height: 30px;
    padding: 0 8px;
    left: 263px;
    z-index: 2;
    display: flex;
    background: #FFFFFF;
    box-shadow: 1px 2px 10px rgba(0, 0, 0, 0.12);
    border-radius: 4px;
    align-items: center;
    cursor: pointer;
}

.right-move img {
    margin-right: 5px;
}

.new-send-div{
    position: absolute;
    bottom: 16px;
    width: calc(50vw - 32px);
    display: flex;
    height: 44px;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 0px 4px;
    background: #F5F7FB;
    border: 1px solid #DFE3E9;
    border-radius: 4px;
    margin: 0 16px 0 16px
}

#messages{
    height: calc(100vh - 132px);
    position: relative;
    overflow:auto
}

@media only screen and (max-width: 600px){
    .new-page-chat-div{
        width: 100vw;
        margin: 0;

    }

    .new-send-div{
        width: calc(100vw - 32px);
    }
}


